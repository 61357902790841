import i18next from 'i18next';
import { CboRole, FeatureFlag, Package } from '@cbo/shared-library';
import { useFeatureFlags } from '../../../utils/hooks/useFeatureFlag';
import isPermitted, { isEligibletoShow } from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { loggedInAsAccountantOnly } from '../../../lib/persona';
import { NavItem } from '.';
import { usePackage } from '../../../contexts/packageContext';

export default function useLaborRoutes(): NavItem[] {
  const [cboTimezoneReports, cboLaborReports, cboLaborPayroll, cboLaborRules, cboLaborTipReports] = useFeatureFlags(
    FeatureFlag.timezoneReports,
    FeatureFlag.laborReports,
    FeatureFlag.laborPayroll,
    FeatureFlag.laborRules,
    FeatureFlag.laborTipReports
  );
  const hasStarterPackage = usePackage(Package.STARTER);
  const user = useUsers();

  const laborRoutes: NavItem[] = [];
  if (isPermitted(user, [CboRole.EMPLOYEE_VIEW, CboRole.EMPLOYEE_CREATE, CboRole.EMPLOYEE_EDIT])) {
    laborRoutes.push({
      title: i18next.t('navItems.employees'),
      href: routes.EMPLOYEE_PAGE,
      testid: 'employees',
    });
  }

  if (hasStarterPackage && isPermitted(user, [CboRole.SCHEDULE_VIEW]) && !loggedInAsAccountantOnly(user)) {
    laborRoutes.push({
      title: i18next.t('navItems.schedulingManager'),
      href: routes.SCHEDULING_MANAGER,
      testid: 'scheduling-manager',
    });
  }

  if (hasStarterPackage && isPermitted(user, [CboRole.ACTUAL_SHIFT_VIEW, CboRole.PLANNED_SHIFT_VIEW])) {
    laborRoutes.push({
      title: i18next.t('navItems.shiftTracker'),
      href: routes.SHIFT_TRACKER,
      testid: 'shift-tracker',
    });
  }

  if (
    isPermitted(user, [
      CboRole.PUNCH_EDIT_SHIFT_VIEW,
      CboRole.PUNCH_EDIT_SHIFT_EDIT,
      CboRole.PUNCH_EDIT_SHIFT_CREATE,
    ]) &&
    cboLaborPayroll
  ) {
    laborRoutes.push({
      title: i18next.t('navItems.punchSummary'),
      href: routes.PUNCH_SUMMARY,
      testid: 'punch-summary',
    });
  }

  // labor reports routes
  const laborReportsChildren: NavItem[] = [];
  if (isEligibletoShow(hasStarterPackage, cboLaborTipReports) && isPermitted(user, [CboRole.ACA_THRESHOLD_VIEW])) {
    laborReportsChildren.push({
      title: i18next.t('navItems.employeeACAReport'),
      href: routes.EMPLOYEE_ACA_THRESHOLD_REPORT,
      testid: 'aca-threshold-report',
    });
  }

  if (hasStarterPackage && isPermitted(user, [CboRole.APPROACHING_OVERTIME_REPORT_VIEW])) {
    laborReportsChildren.push({
      title: i18next.t('navItems.approachingOvertimeThreshold'),
      href: routes.APPROACHING_OVERTIME_THRESHOLD,
      testid: 'approaching-overtime-threshold',
    });
  }

  if (
    isEligibletoShow(hasStarterPackage, cboLaborTipReports, cboTimezoneReports, cboLaborRules) &&
    isPermitted(user, [CboRole.EMPLOYEE_BREAK_VIEW])
  ) {
    laborReportsChildren.push({
      title: i18next.t('navItems.employeeBreak'),
      href: routes.EMPLOYEE_BREAK,
      testid: 'employee-break',
    });
  }

  if (cboTimezoneReports && isPermitted(user, [CboRole.PAYROLL_VIEW, CboRole.EMPLOYEE_VIEW])) {
    laborReportsChildren.push({
      title: i18next.t('navItems.employeePayrollSummaryReport'),
      href: routes.EMPLOYEE_PAYROLL_SUMMARY_REPORT,
      testid: 'employee-payroll-summary-report',
    });
  }

  if (
    isEligibletoShow(hasStarterPackage, cboLaborTipReports) &&
    isPermitted(user, [CboRole.EMPLOYEE_PERFORMANCE_VIEW])
  ) {
    laborReportsChildren.push({
      title: i18next.t('navItems.employeeSalesPerformance'),
      href: routes.EMPLOYEE_SALES_PERFORMANCE,
      testid: 'employee-sales-performance',
    });
  }

  if (isEligibletoShow(hasStarterPackage, cboLaborTipReports) && isPermitted(user, [CboRole.PAYROLL_EXPORT_VIEW])) {
    laborReportsChildren.push({
      title: i18next.t('navItems.genericPayrollExport'),
      href: routes.GENERIC_PAYROLL_EXPORT,
      testid: 'generic-payroll-export',
    });
  }

  if (isEligibletoShow(hasStarterPackage, cboLaborTipReports) && isPermitted(user, [CboRole.EMPLOYEE_TIPS_VIEW])) {
    laborReportsChildren.push({
      title: i18next.t('navItems.employeeTipsReport'),
      href: routes.EMPLOYEE_TIP_REPORT,
      testid: 'employee-tip-report',
    });
  }

  if (
    isEligibletoShow(hasStarterPackage, cboLaborTipReports, cboTimezoneReports) &&
    isPermitted(user, [CboRole.INTERVAL_SALES_LABOR_VIEW])
  ) {
    laborReportsChildren.push({
      title: i18next.t('navItems.intervalSalesAndLaborReport'),
      href: routes.INTERVAL_SALES_AND_LABOR,
      testid: 'interval-sales-labor-report',
    });
  }

  if (cboLaborReports && laborReportsChildren.length > 0) {
    laborRoutes.push({
      title: i18next.t('navItems.laborReports'),
      children: laborReportsChildren,
      testid: 'labor-reports',
    });
  }

  return laborRoutes;
}
