import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { BSLEmploymentStatus } from '@cbo/shared-library';
import { bslError, checkFirebaseErrorCodeExists, HttpsError } from '../../contexts/firebaseApiContext';
import {
  AddPayRateRequestBody,
  ContactInformation,
  Employee,
  PatchPayRateRequestBody,
} from '../services/employeeService';
import {
  UpdateEmployeeContactInfoRequestSchema,
  ChangeJobActivationStatusDto,
  ContactInfoUpdateRequestSchema,
  EmployeeRequestSchema,
  EmployeeSelfRequestSchema,
  UpdateEmergencyContactRequestSchema,
  UpdateEmployeeEmergencyContactRequestSchema,
  UpdateEmployeeCertificationSchema,
  UpdateEmployeeConfigurationSchema,
  UpdateEmployeeDetailsSchemaNew,
  UpdateEmployeeJobCodeAssignmentSchema,
  UpdateEmployeePermissionsSchema,
  UpdateEmployeeRequestSchema,
  UpdateJobcodeSchema,
  UpdateEmployeePersonalInfoRequestSchema,
  UpdateJobDto,
  UpdatePersonalInfoSchema,
  UpdateResponse,
  UpdateEmployeeCertificationInfoSchema,
  EmployeeContactDetailsInformation,
  EmploymentHistoryIdResponse,
  UpdateEmployeeNewRequestSchema,
  UpdateEmployeeHistorySchema,
  JobProfile,
  UpdateJobProfileDto,
  CreateSelfEmployeeRequestSchema,
  CreateSelfEmployeeResponse,
  UpdateUserStatusResponse,
} from '../types';
import useLaborRequests from './index';

export const useCreateSelfEmployeeMutation = (): UseMutationResult<
  Employee | void,
  unknown,
  EmployeeSelfRequestSchema,
  unknown
> => {
  const { createSelfEmployee } = useLaborRequests();
  return useMutation((employee: EmployeeSelfRequestSchema) => createSelfEmployee(employee), {
    useErrorBoundary: true,
  });
};

export const useCreateSelfEmploymentConfigurationMutation = (): UseMutationResult<
  Employee | void,
  unknown,
  { enterpriseUnitId: string },
  unknown
> => {
  const { createSelfEmploymentConfiguration } = useLaborRequests();
  return useMutation((createSelfEmploymentConfigurationVariables: { enterpriseUnitId: string }) =>
    createSelfEmploymentConfiguration(createSelfEmploymentConfigurationVariables.enterpriseUnitId)
  );
};

export const useUpdateSelfContactInfoMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  ContactInformation
> => {
  const { updateSelfContactInformation } = useLaborRequests();
  return useMutation((contactInfo: ContactInformation) => updateSelfContactInformation(contactInfo), {
    useErrorBoundary: true,
  });
};

export const useUpdateContactInfoMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; contactInfo: ContactInfoUpdateRequestSchema }
> => {
  const { updateContactInformation } = useLaborRequests();
  return useMutation(
    (updateContactInfoVariables: { employeeId: string; contactInfo: ContactInfoUpdateRequestSchema }) =>
      updateContactInformation(updateContactInfoVariables.employeeId, updateContactInfoVariables.contactInfo),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdatePersonalInfoMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; personalInfo: UpdatePersonalInfoSchema }
> => {
  const { updatePersonalInformation } = useLaborRequests();
  return useMutation(
    (updatePersonalInfoVariables: { employeeId: string; personalInfo: UpdatePersonalInfoSchema }) =>
      updatePersonalInformation(updatePersonalInfoVariables.employeeId, updatePersonalInfoVariables.personalInfo),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateContactInformationMutationNew = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; contactInfo: EmployeeContactDetailsInformation }
> => {
  const { updateContactInformationNew } = useLaborRequests();
  return useMutation(
    (updatePersonalInfoVariables: { employeeId: string; contactInfo: EmployeeContactDetailsInformation }) =>
      updateContactInformationNew(updatePersonalInfoVariables.employeeId, updatePersonalInfoVariables.contactInfo),
    {
      useErrorBoundary: false,
    }
  );
};

export const useUpdateEmployeeDetailsMutationNew = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; employeeDetails: UpdateEmployeeDetailsSchemaNew }
> => {
  const { updateEmployeeDetailsNew } = useLaborRequests();
  return useMutation(
    (updateEmployeeBirthdateVariables: { employeeId: string; employeeDetails: UpdateEmployeeDetailsSchemaNew }) =>
      updateEmployeeDetailsNew(
        updateEmployeeBirthdateVariables.employeeId,
        updateEmployeeBirthdateVariables.employeeDetails
      ),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmergencyContactsMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeId: string; emergencyContacts: UpdateEmergencyContactRequestSchema }
> => {
  const { updateEmergencyContacts } = useLaborRequests();
  return useMutation(
    (updateEmergencyInfoVariables: { employeeId: string; emergencyContacts: UpdateEmergencyContactRequestSchema }) =>
      updateEmergencyContacts(updateEmergencyInfoVariables.employeeId, updateEmergencyInfoVariables.emergencyContacts),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateCertificationsMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  {
    certificateId: string;
    updateCertifications: UpdateEmployeeCertificationSchema;
  }
> => {
  const { updateCertifications } = useLaborRequests();
  return useMutation(
    (updateCertificationVariables: {
      certificateId: string;
      updateCertifications: UpdateEmployeeCertificationSchema;
    }) =>
      updateCertifications(
        updateCertificationVariables.certificateId,
        updateCertificationVariables.updateCertifications
      ),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeCertificationMutation = (
  employeeId: string,
  certificationId?: string | null
): UseMutationResult<UpdateResponse | void, unknown, UpdateEmployeeCertificationInfoSchema> => {
  const { updateEmployeeCertification, createEmployeeCertification } = useLaborRequests();
  return useMutation(
    (certification: UpdateEmployeeCertificationInfoSchema) =>
      certificationId
        ? updateEmployeeCertification(employeeId, certificationId, certification)
        : createEmployeeCertification(employeeId, certification),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeConfigurationMutation = (
  employeeConfigurationId: string
): UseMutationResult<UpdateResponse | void, HttpsError, UpdateEmployeePermissionsSchema, unknown> => {
  const { updatePermissions } = useLaborRequests();
  return useMutation(
    (employeeConfig: UpdateEmployeePermissionsSchema) => updatePermissions(employeeConfigurationId, employeeConfig),
    {
      useErrorBoundary: (error: HttpsError) => {
        if (checkFirebaseErrorCodeExists(error, 'functions/invalid-argument')) {
          return false;
        }

        return true;
      },
    }
  );
};

export const useUpdateSelfEmployeeMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  UpdateEmployeeRequestSchema
> => {
  const { updateSelfEmployee } = useLaborRequests();
  return useMutation((employee: UpdateEmployeeRequestSchema) => updateSelfEmployee(employee));
};

export const useUpdateSelfPersonalInfoMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  UpdatePersonalInfoSchema
> => {
  const { updateSelfPersonalInformation } = useLaborRequests();
  return useMutation((personalInfo: UpdatePersonalInfoSchema) => updateSelfPersonalInformation(personalInfo), {
    useErrorBoundary: true,
  });
};

export const useUpdateSelfEmergencyContactMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  UpdateEmergencyContactRequestSchema
> => {
  const { updateSelfEmergencyContact } = useLaborRequests();
  return useMutation(
    (emergencyContact: UpdateEmergencyContactRequestSchema) => updateSelfEmergencyContact(emergencyContact),
    { useErrorBoundary: true }
  );
};

export const useUpdateEmployeeNewMutation = (
  employeeId: string
): UseMutationResult<UpdateResponse | void, unknown, UpdateEmployeeNewRequestSchema> => {
  const { updateEmployeeNew } = useLaborRequests();

  return useMutation(
    (updateEmployeeNewVariables: UpdateEmployeeNewRequestSchema) =>
      updateEmployeeNew(employeeId, updateEmployeeNewVariables),
    { useErrorBoundary: false }
  );
};

export const useCreateEmploymentHistoryMutation = (): UseMutationResult<
  EmploymentHistoryIdResponse | void,
  unknown,
  { employeeId: string; employmentHistory: UpdateEmployeeHistorySchema }
> => {
  const { createEmploymentHistory } = useLaborRequests();
  return useMutation(
    (createEmploymentHistoryPayload: { employeeId: string; employmentHistory: UpdateEmployeeHistorySchema }) =>
      createEmploymentHistory(
        createEmploymentHistoryPayload.employeeId,
        createEmploymentHistoryPayload.employmentHistory
      )
  );
};

export const useUpdateEmploymentHistoryMutation = (): UseMutationResult<
  void,
  unknown,
  { employeeId: string; employmentHistoryId: string; employmentHistory: UpdateEmployeeHistorySchema }
> => {
  const { updateEmploymentHistory } = useLaborRequests();
  return useMutation(
    (updateEmploymentHistoryPayload: {
      employeeId: string;
      employmentHistoryId: string;
      employmentHistory: UpdateEmployeeHistorySchema;
    }) =>
      updateEmploymentHistory(
        updateEmploymentHistoryPayload.employeeId,
        updateEmploymentHistoryPayload.employmentHistoryId,
        updateEmploymentHistoryPayload.employmentHistory
      )
  );
};

export const useUpdateSelfEmployeeInformationMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  | UpdateEmployeeEmergencyContactRequestSchema
  | UpdateEmployeeContactInfoRequestSchema
  | UpdateEmployeePersonalInfoRequestSchema
> => {
  const { updateSelfEmployeeInfo } = useLaborRequests();
  return useMutation(
    (
      employeeInformation:
        | UpdateEmployeeEmergencyContactRequestSchema
        | UpdateEmployeeContactInfoRequestSchema
        | UpdateEmployeePersonalInfoRequestSchema
    ) => updateSelfEmployeeInfo(employeeInformation),
    { useErrorBoundary: false }
  );
};

export const useUpdateSelfCertificationMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  UpdateEmployeeCertificationSchema
> => {
  const { updateSelfCertification } = useLaborRequests();
  return useMutation((certification: UpdateEmployeeCertificationSchema) => updateSelfCertification(certification), {
    useErrorBoundary: true,
  });
};

export const useUpdateSelfEmployeeCertificationMutation = (
  certificateId: string
): UseMutationResult<UpdateResponse | void, unknown, UpdateEmployeeCertificationInfoSchema> => {
  const { updateSelfEmployeeCertification, createSelfEmployeeCertification } = useLaborRequests();
  return useMutation(
    (certification: UpdateEmployeeCertificationSchema) =>
      certificateId
        ? updateSelfEmployeeCertification(certification, certificateId)
        : createSelfEmployeeCertification(certification),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeConfigMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeConfigurationId: string; updatedEmployeeConfig: UpdateEmployeeConfigurationSchema }
> => {
  const { updateEmployeeConfiguration } = useLaborRequests();
  return useMutation(
    (updateConfigurationVariables: {
      employeeConfigurationId: string;
      updatedEmployeeConfig: UpdateEmployeeConfigurationSchema;
    }) =>
      updateEmployeeConfiguration(
        updateConfigurationVariables.employeeConfigurationId,
        updateConfigurationVariables.updatedEmployeeConfig
      )
  );
};

export const useUpdateEmployeeUserStatusMutation = (): UseMutationResult<
  UpdateUserStatusResponse | void,
  unknown,
  { username: string; status: BSLEmploymentStatus }
> => {
  const { updateUserStatus } = useLaborRequests();
  return useMutation((updateUserStatusVariables: { username: string; status: BSLEmploymentStatus }) =>
    updateUserStatus(updateUserStatusVariables.username, updateUserStatusVariables.status)
  );
};

export const useUpdateEmployeeInformationMutation = (): UseMutationResult<
  unknown | typeof bslError,
  unknown,
  { employeeId: string; updatedEmployeeInformation: EmployeeRequestSchema }
> => {
  const { updateEmployee } = useLaborRequests();
  return useMutation(
    (updateEmployeeVariables: { employeeId: string; updatedEmployeeInformation: EmployeeRequestSchema }) =>
      updateEmployee(updateEmployeeVariables.employeeId, updateEmployeeVariables.updatedEmployeeInformation),
    {
      useErrorBoundary: (error) => {
        if (checkFirebaseErrorCodeExists(error as HttpsError, 'functions/already-exists')) {
          // allow for caller to handle this error condition (displays snackbar instead of generic error)
          // server returns 409 when employee has not finished registering.
          return false;
        }

        return true;
      },
    }
  );
};

export const useUpdateJobcodeMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { jobcodeId: string; updateJobcodeDto: UpdateJobcodeSchema },
  unknown
> => {
  const { updateJobcode } = useLaborRequests();
  return useMutation(
    (updateJobcodeVariables: { jobcodeId: string; updateJobcodeDto: UpdateJobcodeSchema }) =>
      updateJobcode(updateJobcodeVariables.jobcodeId, updateJobcodeVariables.updateJobcodeDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useCreatePayRateForJobCodeMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    jobCodeId: string;
    employeeConfigurationId: string;
    payRateData: AddPayRateRequestBody;
  },
  unknown
> => {
  const { createPayRateForEmployeeConfigJobCode } = useLaborRequests();
  return useMutation(
    (createPayRateVariables: {
      jobCodeId: string;
      employeeConfigurationId: string;
      payRateData: AddPayRateRequestBody;
    }) =>
      createPayRateForEmployeeConfigJobCode(
        createPayRateVariables.jobCodeId,
        createPayRateVariables.employeeConfigurationId,
        createPayRateVariables.payRateData
      ),
    {
      useErrorBoundary: true,
    }
  );
};

export const usePatchPayRateMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    payRateId: string;
    payRateData: PatchPayRateRequestBody;
  },
  unknown
> => {
  const { patchPayRate } = useLaborRequests();
  return useMutation(({ payRateId, payRateData }) => patchPayRate(payRateId, payRateData), {
    useErrorBoundary: true,
  });
};

export const useDeactivateEmployeeJobcodeMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    employeeConfigId: string;
    jobCodeId: string;
  },
  unknown
> => {
  const { deactivateEmployeeJobcode } = useLaborRequests();
  return useMutation(({ employeeConfigId, jobCodeId }) => deactivateEmployeeJobcode(jobCodeId, employeeConfigId), {
    useErrorBoundary: true,
  });
};

export const useUpdateLaborGroupToJobCodeMappingMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    laborGroupId: string;
    jobCodeDefinitionId: string;
  },
  unknown
> => {
  const { updateLaborGroupToJobCodeMapping } = useLaborRequests();
  return useMutation(
    ({ laborGroupId, jobCodeDefinitionId }) => updateLaborGroupToJobCodeMapping(laborGroupId, jobCodeDefinitionId),
    {
      useErrorBoundary: true,
    }
  );
};

export const useDeleteLaborGroupToJobCodeMappingMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    laborGroupId: string;
    jobCodeDefinitionId: string;
  },
  unknown
> => {
  const { deleteLaborGroupToJobCodeMapping } = useLaborRequests();
  return useMutation(
    ({ laborGroupId, jobCodeDefinitionId }) => deleteLaborGroupToJobCodeMapping(laborGroupId, jobCodeDefinitionId),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateEmployeeJobcodeAssignmentMutation = (): UseMutationResult<
  UpdateResponse | void,
  unknown,
  { employeeJobCodeAssignmentId: string; employeeJobCodeAssignment: UpdateEmployeeJobCodeAssignmentSchema },
  unknown
> => {
  const { updateEmployeeJobcodeAssignment } = useLaborRequests();
  return useMutation(
    ({ employeeJobCodeAssignmentId, employeeJobCodeAssignment }) =>
      updateEmployeeJobcodeAssignment(employeeJobCodeAssignmentId, employeeJobCodeAssignment),
    { useErrorBoundary: true }
  );
};

export const useUpdateJobMutation = (): UseMutationResult<
  void,
  unknown,
  { jobId: string; updateJobDto: UpdateJobDto },
  unknown
> => {
  const { updateJob } = useLaborRequests();

  return useMutation(
    (updateJobcodeVariables: { jobId: string; updateJobDto: UpdateJobDto }) =>
      updateJob(updateJobcodeVariables.jobId, updateJobcodeVariables.updateJobDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useChangeJobActivationStatusMutation = (): UseMutationResult<
  void,
  unknown,
  { jobId: string; changeJobActivationDto: ChangeJobActivationStatusDto },
  unknown
> => {
  const { changeJobActivation } = useLaborRequests();

  return useMutation(
    (changeJobActivationVariables: { jobId: string; changeJobActivationDto: ChangeJobActivationStatusDto }) =>
      changeJobActivation(changeJobActivationVariables.jobId, changeJobActivationVariables.changeJobActivationDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useCreateJobProfileMutation = (): UseMutationResult<string | void, unknown, JobProfile, unknown> => {
  const { createJobProfile } = useLaborRequests();
  return useMutation((jobProfile: JobProfile) => createJobProfile(jobProfile), {
    useErrorBoundary: true,
  });
};

export const useUpdateJobProfileMutation = (): UseMutationResult<
  string | void,
  unknown,
  { jobProfileId: string; updateJobProfileDto: UpdateJobProfileDto },
  unknown
> => {
  const { updateJobProfile } = useLaborRequests();
  return useMutation(
    (updateJobProfileVariables: { jobProfileId: string; updateJobProfileDto: UpdateJobProfileDto }) =>
      updateJobProfile(updateJobProfileVariables.jobProfileId, updateJobProfileVariables.updateJobProfileDto),
    {
      useErrorBoundary: true,
    }
  );
};

export const useDeleteJobProfileMutation = (): UseMutationResult<
  string | void,
  unknown,
  {
    jobProfileId: string;
  },
  unknown
> => {
  const { deleteJobProfile } = useLaborRequests();
  return useMutation(({ jobProfileId }) => deleteJobProfile(jobProfileId), {
    useErrorBoundary: true,
  });
};

export const useCreateSelfEmployeeMutationNew = (): UseMutationResult<
  CreateSelfEmployeeResponse | void,
  unknown,
  CreateSelfEmployeeRequestSchema,
  unknown
> => {
  const { createSelfEmployeeNew } = useLaborRequests();
  return useMutation((employee: CreateSelfEmployeeRequestSchema) => createSelfEmployeeNew(employee), {
    useErrorBoundary: true,
  });
};
