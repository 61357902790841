import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

const formatTimeStamp = (date: string, time: string | undefined): string => {
  const timeArray = (time ?? '00:00').split(':');
  return `${date}T${(timeArray.length > 2 ? timeArray.slice(0, -1) : timeArray).join(':')}:00.000Z`;
};

const removeTimezoneSuffix = (date: string | undefined): string => (date ? date.split('.')[0] : '');

const removeTimezone = (date: Date): Date => new Date(removeTimezoneSuffix(new Date(date).toISOString()));

const convertFromUtcToTimezone = (date: string, timeZone: string): string =>
  timeZone ? dayjs(date).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss') : date;

const convertToUtc = (date: string, timeZone: string): string =>
  timeZone ? dayjs.tz(date, timeZone).utc().format() : date;

const DateUtilities = {
  formatTimeStamp,
  removeTimezone,
  removeTimezoneSuffix,
  convertFromUtcToTimezone,
  convertToUtc,
};

export default DateUtilities;
