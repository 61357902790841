import { Res } from '@cbo/shared-library';
import { DataGridPremiumProps, GridColTypeDef } from '@mui/x-data-grid-premium';
import { isNil } from 'lodash';
import { Theme } from '@mui/material';
import { formatCurrency } from '../formatters';

// eslint-disable-next-line import/prefer-default-export
export const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.hierarchy;

// `GridColTypeDef`s

// round to nearest integer
export const integerColDef = (showDashes?: boolean): GridColTypeDef => ({
  type: 'number',
  valueFormatter: (value: number) => {
    if (isNil(value)) {
      return '';
    }

    if (value === 0 && showDashes) {
      return '--';
    }

    return Math.round(Number(value));
  },
});

// round to 2 decimal places
export const hoursColDef: GridColTypeDef = {
  type: 'number',
  valueFormatter: (value: number) => {
    if (isNil(value)) {
      return '';
    }

    return Number(value).toFixed(2);
  },
};

// percentage
export const percentageColDef: GridColTypeDef = {
  type: 'number',
  valueFormatter: (value: number) => {
    if (isNil(value)) {
      return '';
    }

    return `${(Number(value) * 100).toFixed(2)}%`;
  },
};

// Date/Time
export const dateColDef: GridColTypeDef = {
  type: 'dateTime',
  valueGetter: (value: string) => value && new Date(value),
  valueFormatter: (value: string) => {
    if (!value) return '';
    const date = new Date(value).toLocaleString().replace(',', '');
    const secondsIdx = date.lastIndexOf(':00');
    return date.slice(0, secondsIdx) + date.slice(secondsIdx + 3);
  },
};

// currency
export const getCurrencyColDef = (preferences: Res.Admin.UserPreferences | null | undefined): GridColTypeDef => ({
  type: 'number',
  align: 'right',
  headerAlign: 'right',
  valueFormatter: (value: number) => {
    if (isNil(value)) {
      return '';
    }

    return formatCurrency(value, preferences);
  },
});

// Misc. utility functions

export function getCurrencyColorString(value: number, theme: Theme): string | undefined {
  if (value > 0) return theme.palette.success.main;
  if (value < 0) return theme.palette.error.main;
  return undefined;
}
