import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import CalendarToday from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import ButtonResetIcon from '../ButtonResetIcon';
import {
  arrowStyling,
  darkBackgroundColor,
  defaultButtonStyling,
  defaultOutlineButtonStyling,
  selectedButtonStyling,
} from '../GlobalFilterBarStyles';
import { ButtonSize } from '../types';

interface DateFilterButtonProps {
  anchorEl: HTMLButtonElement | null;
  buttonSize: ButtonSize;
  buttonText: string;
  handleReset: () => void;
  resetEnabled: boolean;
  predefined: boolean | undefined;
  handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dates?: DateRange<dayjs.Dayjs>;
  date?: dayjs.Dayjs | null;
  iconOnly?: boolean;
  showBadge?: boolean;
}

function DateFilterButton(props: DateFilterButtonProps) {
  const {
    anchorEl,
    buttonSize,
    buttonText,
    handleReset,
    resetEnabled,
    predefined,
    handleButtonClick,
    dates,
    date,
    iconOnly,
    showBadge,
  } = props;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleButtonSx = () => {
    if (isDarkMode) {
      if (resetEnabled || predefined) {
        return {
          backgroundColor: darkBackgroundColor,
          color: theme.palette.text.primary,
          '& .MuiButton-startIcon': { marginRight: '0px' },
        };
      }
      return {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.text.primary,
        '& .MuiButton-startIcon': { marginRight: '0px' },
      };
    }

    if (iconOnly) {
      if (anchorEl || date?.isValid()) {
        return selectedButtonStyling(null, theme);
      }
      return defaultOutlineButtonStyling(theme);
    }

    if (resetEnabled || predefined) {
      return selectedButtonStyling(anchorEl, theme);
    }

    return defaultButtonStyling(anchorEl, theme);
  };

  const dateText = useMemo(() => {
    if (date?.isValid()) {
      return `${date.format('L')}`;
    }
    if (dates) {
      if (dates[0]?.isValid() && dates[1]?.isValid()) {
        if (dates[0].isSame(dates[1], 'day')) {
          return dates[0].format('L');
        }
        return `${dates[0].format('L')} - ${dates[1].format('L')}`;
      }
    }
    return buttonText;
  }, [date, dates, buttonText]);

  if (iconOnly) {
    return (
      <Button
        startIcon={
          <Badge
            color='error'
            variant='dot'
            invisible={!showBadge}
            slotProps={{
              badge: {
                'data-testid': 'date-picker-badge',
              } as object,
            }}
            sx={{
              '& .MuiBadge-badge': {
                right: 3,
                top: 4,
                border: '0px',
                width: '12px',
                height: '12px',
              },
            }}
          >
            <CalendarToday color='primary' />
          </Badge>
        }
        role='button'
        aria-haspopup='true'
        data-testid='min-single-date-filter-button'
        sx={handleButtonSx}
        onClick={handleButtonClick}
      >
        <Typography variant='button' color={theme.palette.primary.main} pl={date?.isValid() ? '12px' : '0px'}>
          {date?.isValid() ? `${date.format('MM/DD/YY')}` : ''}
        </Typography>
      </Button>
    );
  }

  return resetEnabled ? (
    <Button
      startIcon={
        predefined && (
          <DateRangeIcon
            sx={{ color: theme.palette.primary.main, fontSize: 'medium', marginLeft: '6px', marginRight: '2px' }}
          />
        )
      }
      role='button'
      aria-haspopup='true'
      data-testid={date ? 'single-date-filter-button' : 'date-filter-button'}
      sx={handleButtonSx}
      onClick={handleButtonClick}
      size={buttonSize}
    >
      <Typography
        sx={{
          marginRight: `${predefined ? '4px' : '0px'}`,
          marginLeft: `${'4px'}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 500,
          lineHeight: '22px',
        }}
        fontSize='inherit'
      >
        {dateText}
      </Typography>
      {resetEnabled && <ButtonResetIcon handleReset={handleReset} />}
    </Button>
  ) : (
    <Button
      role='button'
      aria-haspopup='true'
      data-testid={date ? 'single-date-filter-button' : 'date-filter-button'}
      sx={handleButtonSx}
      onClick={handleButtonClick}
      size={buttonSize}
      startIcon={
        predefined && (
          <DateRangeIcon
            sx={{ color: theme.palette.primary.main, fontSize: 'medium', marginLeft: '6px', marginRight: '2px' }}
          />
        )
      }
    >
      <Typography
        sx={{
          marginRight: '4px',
          marginLeft: `${predefined ? '4px' : '8px'}`,
          fontWeight: 500,
          lineHeight: '22px',
        }}
        fontSize='inherit'
      >
        {predefined && dateText}
      </Typography>
      {anchorEl ? (
        <ArrowDropUpOutlinedIcon sx={arrowStyling(theme, Boolean(predefined))} fontSize='inherit' />
      ) : (
        <ArrowDropDownIcon sx={arrowStyling(theme, Boolean(predefined))} fontSize='inherit' />
      )}
    </Button>
  );
}

DateFilterButton.defaultProps = {
  dates: undefined,
  date: null,
  iconOnly: false,
  showBadge: false,
};

export default DateFilterButton;
