// TODO: Deprecate this file in favor of react-queries in /requests folder
import { Req, Res } from '@cbo/shared-library';
import { useMemo } from 'react';
import useCallBsl from '../utils/hooks/useCallBsl';
import toQueryParams from '../utils/objectUtils/toQueryParams';

export const proxies = {
  notifications: 'notifications',
  calendar: 'calendar',
  siteManagement: 'site-management',
  admin: 'admin',
  glAccounts: 'gl-accounts',
};
const Verb = Req.Firebase.HttpVerb;

export const useAdminRequests = () => {
  const callBsl = useCallBsl();
  return useMemo(
    () => ({
      getNotifications: async (): Promise<Res.Notifications.Notification[]> =>
        callBsl<{ data: Res.Notifications.Notification[] }>({
          proxy: proxies.notifications,
          verb: Verb.GET,
          pathSegments: ['notifications'],
        }).then(({ data }) => data.map((item) => ({ ...item, createdAt: new Date(item.createdAt) }))),

      markNotificationAsRead: async (notificationId: string): Promise<Res.Notifications.Notification> =>
        callBsl({
          proxy: proxies.notifications,
          verb: Verb.PUT,
          pathSegments: ['notifications', notificationId, 'mark-as-read'],
        }),

      markNotificationAsUnread: async (notificationId: string): Promise<Res.Notifications.Notification> =>
        callBsl({
          proxy: proxies.notifications,
          verb: Verb.PUT,
          pathSegments: ['notifications', notificationId, 'mark-as-unread'],
        }),

      markAllNotificationAsRead: async (): Promise<Res.Notifications.Notification> =>
        callBsl({
          proxy: proxies.notifications,
          verb: Verb.PUT,
          pathSegments: ['notifications', 'mark-all-as-read'],
        }),

      dismissNotification: async (notificationId: string): Promise<Res.Notifications.Notification> =>
        callBsl({
          proxy: proxies.notifications,
          verb: Verb.PUT,
          pathSegments: ['notifications', notificationId, 'dismiss'],
        }),

      getEventTypesWithNotificationSettingsIds: async (
        notificationSettingsId: string
      ): Promise<Res.Notifications.EventTypeWithNotificationSettingsId[]> =>
        callBsl<Res.Notifications.EventTypeWithNotificationSettingsId[]>({
          proxy: proxies.notifications,
          verb: Verb.GET,
          pathSegments: ['event-types'],
          queryParams: toQueryParams({ notificationSettingsId }),
        }).catch((err) => {
          // TODO: Error handling
          // eslint-disable-next-line no-console
          console.error(err);
          return [];
        }),

      createSiteGroup: async (groupData: Req.SiteManagement.SiteGroupData): Promise<Res.SiteManagement.SiteGroupData> =>
        callBsl({
          proxy: proxies.siteManagement,
          verb: Verb.POST,
          pathSegments: ['site-groups'],
          payload: groupData,
        }),
    }),
    [callBsl]
  );
};
