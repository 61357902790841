import { Req } from '@cbo/shared-library';
import { HttpsError } from '../contexts/firebaseApiContext';
import useCallBsl from '../utils/hooks/useCallBsl';
import useCallUpdateUserStatus from '../utils/hooks/useCallUpdateUserStatus';

const callBslAndHandleErrors = async <T, E>(
  request: Req.Firebase.BslProxyRequest,
  callFunction: (request: Req.Firebase.BslProxyRequest) => Promise<T>,
  handleError: (error: unknown) => E,
  errorMessageMapper?: Record<string, string>
): Promise<T | void> => {
  try {
    return await callFunction(request);
  } catch (err) {
    const firebaseStatus = (err as HttpsError).code;
    if (errorMessageMapper) {
      const errorKey = firebaseStatus.substring(firebaseStatus.indexOf('/') + 1);
      if (errorKey in errorMessageMapper) {
        // Call handleError instead of returning it because the promise was resolving with undefined, thus
        // triggering the onSuccess callback in mutations even when the request failed
        handleError(`${errorMessageMapper[errorKey]}`);
      }
    }
    // TODO: Update to remove caught and then thrown error for error boundary
    throw err;
  }
};

export const useCallBslAndHandleErrors = () => {
  const callBsl = useCallBsl();
  return <T, E>(
    request: Req.Firebase.BslProxyRequest,
    handleError: (error: unknown) => E,
    errorMessageMapper?: Record<string, string>
  ): Promise<T | void> => callBslAndHandleErrors<T, E>(request, callBsl, handleError, errorMessageMapper);
};

export const useUpdateUserStatusAndHandleErrors = () => {
  const updateUserStatus = useCallUpdateUserStatus();
  return <T, E>(
    request: Req.Firebase.BslProxyRequest,
    handleError: (error: unknown) => E,
    errorMessageMapper?: Record<string, string>
  ): Promise<T | void> => callBslAndHandleErrors<T, E>(request, updateUserStatus, handleError, errorMessageMapper);
};

export default useCallBslAndHandleErrors;
