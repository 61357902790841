import { CboRole, Package } from '@cbo/shared-library';
import { User } from '../models/User';

const isPermitted = (user: User, roles: CboRole[], needsAllRoles?: boolean): boolean => {
  const userRoles = user.bslAuth?.roles || [];

  if (needsAllRoles) {
    return roles.filter((item) => userRoles.indexOf(item) >= 0).length === roles.length;
  }
  return roles.some((item) => userRoles.includes(item));
};
export const isEligibletoShow = (...args: boolean[]) => args.every((flag: boolean) => flag);

export const hasPackagePermissions = (packages: Record<Package, boolean>, packagesRequired: Package[]) =>
  packagesRequired.every((pkg) => packages[pkg]);

export default isPermitted;
