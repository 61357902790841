import { useCallback } from 'react';
import { Req } from '@cbo/shared-library';
import { useFirebaseApi } from '../../contexts/firebaseApiContext';

export default () => {
  const { updateUserStatus } = useFirebaseApi();

  return useCallback(
    async <T>(request: Req.Firebase.BslProxyRequest): Promise<T> => {
      if (!updateUserStatus) {
        throw new Error('updateUserStatus firebase function is not assigned');
      }

      const result = await updateUserStatus(request);
      return result.data as T;
    },
    [updateUserStatus]
  );
};
