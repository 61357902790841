import { Req, Res } from '@cbo/shared-library';
import { SiteManagement } from '@cbo/shared-library/response';
import {
  EnterpriseUnitGroupViewData,
  PagedResponse,
  SiteViewData,
} from '@cbo/shared-library/response/site-management.response';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import staleTimeSettings from '../../config/reactQueryConfig';
import { MessageResponse } from '../models/MessageResponse';
import { GetAllGlMappingsRequest, GlMappingFilterResponse, GlMappingsResponse, useAdminRequests } from './index';
import queryKeys from './queryKeys';

/**
 * USER PREFERENCES
 */
export const useUserPreferencesDataQuery = (
  isUserAuthenticated: boolean
): UseQueryResult<Res.Admin.UserPreferences> => {
  const { getUserPreferencesData } = useAdminRequests();

  return useQuery(queryKeys.userPreferences.preferences(), () => getUserPreferencesData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

/**
 * COMPANY LINKS
 */
export const useCompanyLinksDataQuery = (
  isUserAuthenticated: boolean,
  filterByUserRoles?: boolean,
  organizationId?: string
): UseQueryResult<Res.Firebase.Link[]> => {
  const { getAllCompanyLinks } = useAdminRequests();

  return useQuery(
    filterByUserRoles
      ? queryKeys.companyLinks.sideBarCompanyLinks(organizationId)
      : queryKeys.companyLinks.companyLinks(organizationId),
    async () => getAllCompanyLinks(filterByUserRoles),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

/**
 * FISCAL CALENDAR
 */
export const useFiscalCalendarDataQuery = (
  isUserAuthenticated: boolean,
  userId: string,
  organizationId?: string
): UseQueryResult<Res.Calendar.FiscalCalendar> => {
  const { getFiscalCalendarData } = useAdminRequests();

  return useQuery(queryKeys.fiscalCalendar.fiscalCalendarById(userId, organizationId), () => getFiscalCalendarData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useFiscalCalendarPreviewDataQuery = (
  isUserAuthenticated: boolean,
  userId: string,
  year: number
): UseQueryResult<Res.Calendar.FiscalCalendarPreview> => {
  const { getFiscalCalendarPreviewData } = useAdminRequests();

  return useQuery(
    queryKeys.fiscalCalendar.fiscalCalendarPreviewById(userId, year),
    () => getFiscalCalendarPreviewData(year),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

/**
 * PAYROLL CALENDAR
 */
export const usePayrollCalendarDataQuery = (
  isUserAuthenticated: boolean,
  siteId: string
): UseQueryResult<Res.Calendar.PayrollCalendar | MessageResponse> => {
  const { getPayrollCalendarData } = useAdminRequests();

  return useQuery(queryKeys.payrollCalendar.calendarBySiteId(siteId), async () => getPayrollCalendarData(siteId), {
    enabled: !!isUserAuthenticated && !!siteId,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const usePayrollCalendarFiltersQuery = (
  siteId: string,
  hasPermissions: boolean
): UseQueryResult<Res.Calendar.PayrollCalendarFilters | MessageResponse> => {
  const { getPayrollCalendarFilters } = useAdminRequests();

  return useQuery(queryKeys.payrollCalendar.filtersBySiteId(siteId), async () => getPayrollCalendarFilters(siteId), {
    enabled: !!siteId && hasPermissions,
    staleTime: staleTimeSettings.HIGH,
    useErrorBoundary: false,
  });
};

export const useAllNotificationSettingsDataQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<Res.Notifications.NotificationSettings[]> => {
  const { getAllNotificationSettingsData } = useAdminRequests();

  return useQuery(
    queryKeys.notificationSettings.allNotificationSettings(organizationId),
    async () => getAllNotificationSettingsData(),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useNotificationSettingsDataQuery = (
  isUserAuthenticated: boolean,
  notificationSettingsId: string
): UseQueryResult<Res.Notifications.NotificationSettings> => {
  const { getNotificationSettingsData } = useAdminRequests();

  return useQuery(
    queryKeys.notificationSettings.notificationSettingsById(notificationSettingsId),
    async () => getNotificationSettingsData(notificationSettingsId),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useAllEventTypesDataQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<{ data: Res.Notifications.EventType[] }> => {
  const { getAllEventTypesData } = useAdminRequests();

  return useQuery(queryKeys.notificationSettings.allEventTypes(organizationId), async () => getAllEventTypesData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useNotificationsDataQuery = (
  isUserAuthenticated: boolean
): UseQueryResult<Res.Notifications.Notification[]> => {
  const { getNotificationsData } = useAdminRequests();

  return useQuery(queryKeys.notifications.allNotifications(), async () => getNotificationsData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.LOW,
    useErrorBoundary: false,
  });
};

/**
 * Site Management
 */
export const useAllSitesDataQuery = (
  isUserAuthenticated: boolean,
  siteIds?: string[],
  organizationId?: string,
  useErrorBoundary = false
): UseQueryResult<Res.SiteManagement.PagedResponse<SiteViewData>> => {
  const { getAllSitesData } = useAdminRequests();
  return useQuery(queryKeys.siteManagement.allSites(siteIds, organizationId), async () => getAllSitesData(siteIds), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary,
  });
};

export const useSiteDataByIdQuery = (
  isUserAuthenticated: boolean,
  siteId: string
): UseQueryResult<Res.SiteManagement.SiteViewData> => {
  const { getSiteDetailsDataById } = useAdminRequests();

  return useQuery(queryKeys.siteManagement.siteDetailsDataById(siteId), async () => getSiteDetailsDataById(siteId), {
    enabled: !!isUserAuthenticated && !!siteId,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useSiteDataByIdsQuery = (
  isUserAuthenticated: boolean,
  siteIds: string[]
): UseQueryResult<Res.SiteManagement.SiteViewData[]> => {
  const { getSiteDetailsDataByIds } = useAdminRequests();

  return useQuery(queryKeys.siteManagement.siteDataByIds(siteIds), async () => getSiteDetailsDataByIds(siteIds), {
    enabled: isUserAuthenticated && !!siteIds.length,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useEuDataByGroupNameQuery = (isUserAuthenticated: boolean, groupName: string) => {
  const { getEuDataByGroupName } = useAdminRequests();
  return useQuery(queryKeys.siteManagement.euDataByGroupName(groupName), async () => getEuDataByGroupName(groupName), {
    enabled: isUserAuthenticated && !!groupName,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useAllSiteGroupsDataQuery = (
  page: number,
  limit: number,
  isUserAuthenticated: boolean,
  searchString?: string,
  organizationId?: string
): UseQueryResult<PagedResponse<SiteManagement.EnterpriseUnitGroupViewData>> => {
  const { getAllSiteGroupsData } = useAdminRequests();

  return useQuery(
    queryKeys.siteManagement.allSiteGroups(page, limit, searchString, organizationId),
    async () => getAllSiteGroupsData(page, limit, searchString),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
      keepPreviousData: true,
    }
  );
};

export const useSiteGroupDataQuery = (
  isUserAuthenticated: boolean,
  groupNameId: string | undefined
): UseQueryResult<EnterpriseUnitGroupViewData> => {
  const { getSiteGroupData } = useAdminRequests();

  return useQuery(
    queryKeys.siteManagement.siteGroupByGroupNameId(groupNameId),
    async () => getSiteGroupData(groupNameId),
    {
      enabled: !!isUserAuthenticated && !!groupNameId,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useEnterpriseUnits = (
  page: number,
  limit: number,
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<PagedResponse<Res.SiteManagement.EnterpriseUnitData>> => {
  const { getEnterpriseUnits } = useAdminRequests();

  return useQuery(
    queryKeys.siteManagement.euData(page, limit, organizationId),
    async () => getEnterpriseUnits(page, limit),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
      keepPreviousData: true,
    }
  );
};

export const useAllSiteTagsDataQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<SiteManagement.SiteTag[]> => {
  const { getAllSiteTagData } = useAdminRequests();

  return useQuery(queryKeys.siteManagement.allSiteTags(organizationId), async () => getAllSiteTagData(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: true,
  });
};

export const useSiteTagDataByIdQuery = (
  isUserAuthenticated: boolean,
  siteTagId: string
): UseQueryResult<SiteManagement.SiteTag> => {
  const { getSiteTagDetailsDataById } = useAdminRequests();

  return useQuery(
    queryKeys.siteManagement.siteTagDataById(siteTagId),
    async () => getSiteTagDetailsDataById(siteTagId),
    {
      enabled: isUserAuthenticated,
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
      cacheTime: 0,
    }
  );
};

export const useSitesAssignedToTagDataQuery = (
  isUserAuthenticated: boolean,
  tagIds: string[]
): UseQueryResult<SiteManagement.SitesAssignedToTagsResponse> => {
  const { getSitesAssignedToTags } = useAdminRequests();

  return useQuery(queryKeys.siteManagement.sitesAssignedToTags(tagIds), async () => getSitesAssignedToTags(tagIds), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

/**
 * Audit Log
 */
export const useAllAuditLogDataQuery = (
  isUserAuthenticated: boolean,
  filter: Req.AuditLog.FilterRequest,
  organizationId?: string
): UseQueryResult<Res.AuditLog.LogEntry[]> => {
  const { getAllAuditLogData } = useAdminRequests();
  return useQuery(queryKeys.auditLog.allAuditLogs(filter, organizationId), async () => getAllAuditLogData(filter), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

export const useAllAuditLogFilterQuery = (
  isUserAuthenticated: boolean,
  organizationId?: string
): UseQueryResult<Res.AuditLog.FilterResponse> => {
  const { getAllAuditLogFilters } = useAdminRequests();
  return useQuery(queryKeys.auditLog.allAuditLogFilters(organizationId), async () => getAllAuditLogFilters(), {
    enabled: isUserAuthenticated,
    staleTime: staleTimeSettings.MEDIUM,
    useErrorBoundary: false,
  });
};

/**
 * GL Mapping
 */
export const useGetAllJobCodeMappings = (body: GetAllGlMappingsRequest): UseQueryResult<GlMappingsResponse[]> => {
  const { getAllJobCodeMappings } = useAdminRequests();

  return useQuery(
    queryKeys.glMapping.jobCodeMappings(body?.dataTypes, body?.entityNames, body?.glAccountNames),
    async () => getAllJobCodeMappings(body),
    {
      staleTime: staleTimeSettings.MEDIUM,
      useErrorBoundary: false,
    }
  );
};

export const useGetGlMappingFiltersQuery = (): UseQueryResult<GlMappingFilterResponse> => {
  const { getGlMappingFilters } = useAdminRequests();

  return useQuery(queryKeys.glMapping.glMappingFilters, async () => getGlMappingFilters(), {
    staleTime: staleTimeSettings.HIGH,
    useErrorBoundary: false,
  });
};

export const useGetAllGlMappings = (
  enabled: boolean,
  body: GetAllGlMappingsRequest
): UseQueryResult<GlMappingsResponse[]> => {
  const { getAllGlMappings } = useAdminRequests();

  return useQuery(
    queryKeys.glMapping.allGlMappings(body?.dataTypes, body?.entityNames, body?.glAccountNames),
    () => getAllGlMappings(body),
    {
      enabled,
    }
  );
};
