import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/system';
import { LineSeriesType } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Currency from '../../../components/Currency';
import DeltaChipIndicator from '../../../components/DeltaIndicatorChip/DeltaIndicatorChip';
import { AccountPreferencesContext } from '../../../contexts/accountPreferencesContext';
import { formatCurrency } from '../../../utils';
import { MobileSize } from '../ScorecardContainer/ScorecardContainer';
import ComparisonRange from '../../../config/comparisonRange';
import { xAxisFormatter } from '../DashboardUtils';

export interface DashboardLineChartProps {
  singleValueProps: {
    title: string;
    value: string | number;
    comparisonValue?: string | number;
    comparisonRange?: ComparisonRange;
    positiveResultsPreferred: boolean;
    currency?: boolean;
    percentage?: boolean;
  };
  url: string;
  mobileSize: MobileSize;
  loading: boolean;
  isSameDay: boolean;
  data: {
    xAxis: string[];
    netSales: number[];
    comparisonNetSales: number[];
    forecastedNetSales: number[];
  };
}

const valueFormatter = <V extends number | null>(value: V): string => `${value}`;

const baseSeriesSettings: Partial<LineSeriesType> = {
  showMark: false,
  area: false,
  connectNulls: false,
  valueFormatter,
};

function DashboardLineChart({ singleValueProps, url, mobileSize, loading, isSameDay, data }: DashboardLineChartProps) {
  const isMobileMediumOrLess = mobileSize && mobileSize !== 'large';
  const theme = useTheme();
  const history = useHistory();
  const { preferences } = useContext(AccountPreferencesContext);

  const onClickHandle = () => {
    history.push(url);
  };

  let formattedValue;

  if (singleValueProps.currency) {
    formattedValue = Currency({ value: singleValueProps.value });
  } else if (singleValueProps.percentage) {
    formattedValue = `${Number(singleValueProps.value).toFixed(2)}%`;
  } else {
    formattedValue = singleValueProps.value;
  }

  const xAxisData = data.xAxis.map((_, i) => i);

  const yAxisFormatter = (value: number | null): string => formatCurrency(value || 0, preferences);

  const curveStyle = data.netSales.filter((value) => value > 0 || value < 0) ? 'linear' : 'natural';

  return (
    <Paper
      sx={{
        border: 1,
        borderColor: '#B8B7B7',
        borderRadius: '8px',
        '&:hover': {
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        },
        width: '100%',
        height: isMobileMediumOrLess ? '142px' : 'auto',
        flex: isMobileMediumOrLess ? '40%' : 'none',
        marginBottom: isMobileMediumOrLess ? '-8px' : 0,
      }}
      data-testid='dashboard-line-chart'
    >
      <Skeleton
        sx={{
          height: isMobileMediumOrLess ? '142px' : '330px',
          width: '100%',
          flex: isMobileMediumOrLess ? '40%' : 'none',
          display: !loading ? 'none' : 'inherit',
        }}
        variant='rounded'
        data-testid='dashboard-line-chart-skeleton'
      />
      <Box
        hidden={loading}
        sx={{
          position: 'relative',
          display: loading ? 'none' : 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
        data-testid='dashboard-line-chart-content'
      >
        <Link
          to={url}
          title={url}
          data-testid='dashboard-line-chart-url'
          style={{ width: '100%', height: '100%', position: 'absolute' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            data-testid='dashboard-line-chart-single-value'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: '15px',
              marginTop: '5px',
            }}
          >
            <Typography
              data-testid='dashboard-line-chart-single-value-value'
              sx={{
                fontSize: '24px',
                fontWeight: '500',
                letterSpacing: '0.15px',
              }}
            >
              {formattedValue}
            </Typography>
            <Typography
              data-testid='dashboard-line-chart-single-value-title'
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '1em',
                letterSpacing: '0.15px',
              }}
            >
              {singleValueProps.title}
            </Typography>
          </Box>
          <Box
            sx={{
              alignSelf: 'flex-end',
              marginRight: '15px',
            }}
          >
            <DeltaChipIndicator
              comparisonRange={singleValueProps.comparisonValue !== 0 ? singleValueProps.comparisonRange : undefined}
              value={singleValueProps.comparisonValue}
              isImprovement={
                singleValueProps.positiveResultsPreferred
                  ? Number(singleValueProps.comparisonValue) > 0
                  : Number(singleValueProps.comparisonValue) < 0
              }
              dataTestId='dashboard-line-chart-delta'
            />
          </Box>
        </Box>
        <Box
          display={isMobileMediumOrLess ? 'none' : 'inherit'}
          height={isMobileMediumOrLess ? 'none' : 'inherit'}
          sx={{
            marginTop: '20px',
            cursor: 'pointer',
          }}
          onClick={onClickHandle}
        >
          <LineChart
            sx={{
              '& .MuiLineElement-series-Actual': {
                strokeWidth: 3,
              },
              '& .MuiLineElement-series-LastYear': {
                strokeDasharray: '6 6',
                strokeWidth: 3,
              },
              '& .MuiLineElement-series-Forecast': {
                strokeWidth: 1,
              },
              '& .MuiAreaElement-series-Forecast': {
                fill: theme.palette.mode === 'light' ? "url('#myGradient')" : '#069887',
              },
              '& .MuiChartsLegend-series:nth-of-type(2) .MuiChartsLegend-mark': {
                fill: "url('#lines')",
              },
            }}
            xAxis={[
              {
                id: 'xAxis',
                data: xAxisData,
                tickInterval: xAxisData,
                valueFormatter: (value: number) => xAxisFormatter(value, isSameDay, data.xAxis),
              },
            ]}
            yAxis={[
              {
                tickInterval: (value) => value >= 0,
                valueFormatter: (value) => formatCurrency(value, preferences, undefined, true, undefined, 0),
              },
            ]}
            series={[
              {
                ...baseSeriesSettings,
                id: 'Actual',
                label: 'Actual',
                color: theme.palette.mode === 'dark' ? '#5283E5' : '#203A7E',
                data: data.netSales,
                curve: curveStyle,
                valueFormatter: yAxisFormatter,
              },
              {
                ...baseSeriesSettings,
                id: 'LastYear',
                label: 'Last year',
                curve: curveStyle,
                color: theme.palette.mode === 'dark' ? '#8A9BFF' : '#4A5AFF',
                data: data.comparisonNetSales,
                valueFormatter: yAxisFormatter,
              },
              {
                ...baseSeriesSettings,
                id: 'Forecast',
                label: 'Forecast',
                data: data.forecastedNetSales,
                curve: curveStyle,
                color: theme.palette.mode === 'dark' ? '#D9F2EF' : '#069887',
                area: true,
                valueFormatter: yAxisFormatter,
              },
            ]}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'right' },
                padding: { top: 0, right: -23 },
                itemGap: 40,
                itemMarkHeight: 3,
                itemMarkWidth: 50,
              },
            }}
            height={250}
            margin={{ right: 15, left: 75 }}
          >
            <defs>
              <linearGradient id='myGradient' gradientTransform='rotate(90)'>
                <stop offset='0%' stopColor='#069887' />
                <stop offset='75%' stopColor='#BCEBE5' />
                <stop offset='100%' stopColor='#D9F2EF' />
              </linearGradient>
              <pattern id='lines' patternUnits='userSpaceOnUse' width='10' height='10'>
                <line
                  x1='1'
                  y1='0'
                  x2='10'
                  y2='0'
                  stroke={theme.palette.mode === 'dark' ? '#8A9BFF' : '#4A5AFF'}
                  height='10'
                  strokeWidth='100%'
                  strokeDasharray='6'
                />
              </pattern>
            </defs>
          </LineChart>
        </Box>
      </Box>
    </Paper>
  );
}

export default DashboardLineChart;
